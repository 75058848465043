<template>
  <ml-cascader
    prop="repairUsers"
    label=""
    :options="options"
    :props="{ multiple: multiple, label: labelKey, value: valueKey }"
    placeholder="请选择负责人"
    v-model="value"
    collapseTags
    v-model:node="modelNode"
  />
</template>

<script>
import { ref, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
  name: 'AmibaAanager',
  props: {
    orderType: {
      type: Number
    },
    multiple: {
      type: Boolean,
      default: false
    },
    labelKey: {
      type: String,
      default: 'userName'
    },
    valueKey: {
      type: String,
      default: 'userId'
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    node: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const { dispatch } = useStore()
    const options = ref([])
    const modelNode = ref([])
    const value = ref(props.modelValue || [])
    watch(
      () => props.modelValue,
      newvalue => {
        value.value = newvalue
      }
    )

    const getAmibaAanager = () => {
      let paramName = ''
      if (props.orderType >= 0) {
        paramName = props.orderType === 0 ? 'fetchGetMineCompanyUsers' : 'fetchGetOtherCompanyUsers'
      }

      dispatch(paramName).then(data => {
        if (data && data.code === 200) {
          if (props.orderType === 0) {
            options.value = data.data || []
          } else {
            if (data.data && Object.keys(data.data).length > 0) {
              options.value = Object.keys(data.data).map((item, index) => {
                return {
                  userName: item,
                  userId: `${item}_${index}`,
                  children: data.data[item].map(res => {
                    return {
                      userName: res.userName,
                      userId: res.userId,
                      companyId: res.companyId
                    }
                  })
                }
              })
            }
          }
        }
      })
    }
    watch(
      () => props.orderType,
      () => {
        options.value = []
        context.emit('update:modelValue', [])
        context.emit('update:node', [])
        getAmibaAanager()
      }
    )
    watch(
      () => value.value,
      newValue => {
        context.emit('update:modelValue', newValue)
      }
    )
    watch(
      () => modelNode.value,
      newValue => {
        context.emit('update:node', newValue)
      }
    )
    return { value, modelNode, options }
  }
}
</script>

<style></style>
